// Create a new cart (guest or user cart)
export const CREATE_CART = 'landers/Checkout/CREATE_CART';
export const CREATE_CART_SUCCESS = 'landers/Checkout/CREATE_CART_SUCCESS';
export const CREATE_CART_FAILED = 'landers/Checkout/CREATE_CART_FAILED';

// Get Cart Info by cartMaskId
export const GET_CART_INFO = 'landers/Checkout/GET_CART_INFO';
export const GET_CART_INFO_SUCCESS = 'landers/Checkout/GET_CART_INFO_SUCCESS';
export const GET_CART_INFO_FAILED = 'landers/Checkout/GET_CART_INFO_FAILED';

// Get Cart Totals Information
export const GET_CART_TOTALS = 'landers/Checkout/GET_CART_TOTALS';
export const GET_CART_TOTALS_SUCCESS =
  'landers/Checkout/GET_CART_TOTALS_SUCCESS';
export const GET_CART_TOTALS_FAILED = 'landers/Checkout/GET_CART_TOTALS_FAILED';
export const SET_LOADING_CART_TOTALS =
  'landers/Checkout/SET_LOADING_CART_TOTALS';

// validate cart items
export const VALIDATE_CART_ITEMS = 'landers/Checkout/VALIDATE_CART_ITEMS';
export const VALIDATE_CART_ITEMS_SUCCESS =
  'landers/Checkout/VALIDATE_CART_ITEMS_SUCCESS';
export const VALIDATE_CART_ITEMS_FAILED =
  'landers/Checkout/VALIDATE_CART_ITEMS_FAILED';

// Sync client cart to server cart
export const SYNC_CART_SERVER = 'landers/Checkout/SYNC_CART_SERVER';
export const SYNC_CART_SERVER_SUCCESS =
  'landers/Checkout/SYNC_CART_SERVER_SUCCESS';
export const SYNC_CART_SERVER_FAILED =
  'landers/Checkout/SYNC_CART_SERVER_FAILED';

// Get cart promotion rules
export const GET_CART_RULES = 'landers/Checkout/GET_CART_RULES';
export const GET_CART_RULES_SUCCESS = 'landers/Checkout/GET_CART_RULES_SUCCESS';
export const GET_CART_RULES_FAILED = 'landers/Checkout/GET_CART_RULES_FAILED';
export const CLEAR_CART_RULES = 'landers/Checkout/CLEAR_CART_RULES';

// Get all delivery timeslots
export const GET_DELIVERY_TIMESLOTS = 'landers/Checkout/GET_DELIVERY_TIMESLOTS';
export const GET_DELIVERY_TIMESLOTS_SUCCESS =
  'landers/Checkout/GET_DELIVERY_TIMESLOTS_SUCCESS';
export const GET_DELIVERY_TIMESLOTS_FAILED =
  'landers/Checkout/GET_DELIVERY_TIMESLOTS_FAILED';

// Get all stores timeslots
export const GET_STORES_TIME_SLOTS = 'landers/Checkout/GET_STORES_TIME_SLOTS';
export const GET_STORES_TIME_SLOTS_SUCCESS =
  'landers/Checkout/GET_STORES_TIME_SLOTS_SUCCESS';
export const GET_STORES_TIME_SLOTS_FAILED =
  'landers/Checkout/GET_STORES_TIME_SLOTS_FAILED';

// Add/Update product to client cart
export const SELECT_DELIVERY_TIMESLOT =
  'landers/Checkout/SELECT_DELIVERY_TIMESLOT';

// validate delivery timeslots
export const VALIDATE_DELIVERY_TIMESLOT =
  'landers/Checkout/VALIDATE_DELIVERY_TIMESLOT';
export const VALIDATE_DELIVERY_TIMESLOT_SUCCESS =
  'landers/Checkout/VALIDATE_DELIVERY_TIMESLOT_SUCCESS';
export const VALIDATE_DELIVERY_TIMESLOT_FAILED =
  'landers/Checkout/VALIDATE_DELIVERY_TIMESLOT_FAILED';

// Get shipping methods
export const GET_SHIPPING_METHODS = 'landers/Checkout/GET_SHIPPING_METHODS';
export const GET_SHIPPING_METHODS_SUCCESS =
  'landers/Checkout/GET_SHIPPING_METHODS_SUCCESS';
export const GET_SHIPPING_METHODS_FAILED =
  'landers/Checkout/GET_SHIPPING_METHODS_FAILED';

// Estimate shipping methods by address Id
export const ESTIMATE_SHIPPING_METHODS =
  'landers/Checkout/ESTIMATE_SHIPPING_METHODS';
export const ESTIMATE_SHIPPING_METHODS_SUCCESS =
  'landers/Checkout/ESTIMATE_SHIPPING_METHODS_SUCCESS';
export const ESTIMATE_SHIPPING_METHODS_FAILED =
  'landers/Checkout/ESTIMATE_SHIPPING_METHODS_FAILED';

// Set selected address to reducer
export const SET_SHIPPING_ADDRESS = 'landers/Checkout/SET_SHIPPING_ADDRESS';

// Get all shipping addresses
export const UPDATE_SHIPPING_ADDRESS =
  'landers/Checkout/UPDATE_SHIPPING_ADDRESS';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS =
  'landers/Checkout/UPDATE_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_SHIPPING_ADDRESS_FAILED =
  'landers/Checkout/UPDATE_SHIPPING_ADDRESS_FAILED';
export const REMOVE_SHIPPING_ADDRESS_INVALID_QUANTITIES =
  'landers/Checkout/REMOVE_SHIPPING_ADDRESS_INVALID_QUANTITIES';

// Set selected payment method
export const SET_PAYMENT_METHOD = 'landers/Checkout/SET_PAYMENT_METHOD';

// Update payment information
export const UPDATE_PAYMENT_INFORMATION =
  'landers/Checkout/UPDATE_PAYMENT_INFORMATION';
export const UPDATE_PAYMENT_INFORMATION_SUCCESS =
  'landers/Checkout/UPDATE_PAYMENT_INFORMATION_SUCCESS';
export const UPDATE_PAYMENT_INFORMATION_FAILED =
  'landers/Checkout/UPDATE_PAYMENT_INFORMATION_FAILED';
export const CANCEL_UPDATE_PAYMENT_INFORMATION =
  'landers/Checkout/CANCEL_UPDATE_PAYMENT_INFORMATION';

// Get order increment ID after successfully payment
export const GET_ORDER_BYID = 'landers/Checkout/GET_ORDER_BYID';
export const GET_ORDER_BYID_SUCCESS = 'landers/Checkout/GET_ORDER_BYID_SUCCESS';
export const GET_ORDER_BYID_FAILED = 'landers/Checkout/GET_ORDER_BYID_FAILED';
export const CLEAR_ORDER_ID = 'landers/Checkout/CLEAR_ORDER_ID';

// Get payment methods
export const GET_PAYMENT_METHODS = 'landers/Checkout/GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_SUCCESS =
  'landers/Checkout/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILED =
  'landers/Checkout/GET_PAYMENT_METHODS_FAILED';

export const GET_MEDIA_PATH_SUCCESS = 'landers/Checkout/GET_MEDIA_PATH_SUCCESS';
// Apply coupon
export const APPLY_COUPON = 'landers/Checkout/APPLY_COUPON';
export const APPLY_COUPON_SUCCESS = 'landers/Checkout/APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILED = 'landers/Checkout/APPLY_COUPON_FAILED';

// Get last order
export const GET_LAST_ORDER_OOS = 'landers/Checkout/GET_LAST_ORDER_OOS';
export const GET_LAST_ORDER_OOS_SUCCESS =
  'landers/Checkout/GET_LAST_ORDER_OOS_SUCCESS';
export const GET_LAST_ORDER_OOS_FAILED =
  'landers/Checkout/GET_LAST_ORDER_OOS_FAILED';

// Add/Update product to client cart
export const SYNC_CART_CLIENT = 'landers/Checkout/SYNC_CART_CLIENT';

// SYNC PRODUCT TO SERVER AND NAVIGATE TO CHECKOUT
export const SYNC_PRODUCT_THEN_GOCHECKOUT =
  'landers/Checkout/SYNC_PRODUCT_THEN_GOCHECKOUT';

// Remove all product in cart
export const REMOVE_ALLPRODUCTS_INCART =
  'landers/Checkout/REMOVE_ALLPRODUCTS_INCART';

// Remove specific product in cart
export const REMOVE_PRODUCT_IN_CART = 'landers/Checkout/REMOVE_PRODUCT_INCART';

// Remove multiple products in cart
export const REMOVE_MULTIPLE_PRODUCTS_IN_CART =
  'landers/Checkout/REMOVE_MULTIPLE_PRODUCTS_INCART';

// Refresh card data when payment success
export const SET_CART_AS_NEED_SYNC = 'landers/Checkout/SET_CART_AS_NEED_SYNC';

// Refresh card data when payment success
export const RESTORE_CART = 'landers/Checkout/RESTORE_CART';

export const SET_CANCELLATION_OPTION =
  'landers/Checkout/SET_CANCELLATION_OPTION';

export const SET_DEFAULT_CANCELLATION_OPTION =
  'landers/Checkout/SET_DEFAULT_CANCELLATION_OPTION';

export const SET_STORE_PICKUP_ADDRESS =
  'landers/Checkout/SET_STORE_PICKUP_ADDRESS';

export const SET_FEE_TOOLTIP_VISIBILITY =
  'landers/Checkout/SET_FEE_TOOLTIP_VISIBILITY';

export const IS_RESTORE_CART_PROCESSING = 'IS_RESTORE_CART_PROCESSING';

export const IS_SUBMITTED_PAYMENT = 'IS_SUBMITTED_PAYMENT';

// Set selected payment card
export const SET_PAYMENT_CARD = 'landers/Checkout/SET_PAYMENT_CARD';

// Get payment methods
export const GET_PAYMENT_CARDS = 'landers/Checkout/GET_PAYMENT_CARDS';
export const GET_PAYMENT_CARDS_SUCCESS =
  'landers/Checkout/GET_PAYMENT_CARDS_SUCCESS';
export const GET_PAYMENT_CARDS_FAILED =
  'landers/Checkout/GET_PAYMENT_CARDS_FAILED';

// Add payment card
export const ADD_PAYMENT_CARDS = 'landers/Checkout/ADD_PAYMENT_CARDS';
export const ADD_PAYMENT_CARDS_SUCCESS =
  'landers/Checkout/ADD_PAYMENT_CARDS_SUCCESS';
export const ADD_PAYMENT_CARDS_FAILED =
  'landers/Checkout/ADD_PAYMENT_CARDS_FAILED';

export const SHOW_SLOW_CART_LOADING = 'landers/Checkout/SHOW_SLOW_CART_LOADING';
export const HIDE_SLOW_CART_LOADING = 'landers/Checkout/HIDE_SLOW_CART_LOADING';

export const GET_VOUCHER_MESSAGE = 'landers/Checkout/GET_VOUCHER_MESSAGE';
export const GET_VOUCHER_MESSAGE_SUCCESS =
  'landers/Checkout/GET_VOUCHER_MESSAGE_SUCCESS';
export const UPDATE_CART = 'landers/Checkout/UPDATE_CART';
export const REMOVE_VOUCHER_MESSAGE = 'landers/Checkout/REMOVE_VOUCHER_MESSAGE';
export const SET_CART_ITEMS_COUNT = 'landers/Checkout/SET_CART_ITEMS_COUNT';

export const SET_IS_SYNCING_PRODUCT = 'landers/Checkout/SET_IS_SYNCING_PRODUCT';

export const RUN_PAYMENT_SUCCESS_PROCESS =
  'landers/Checkout/RUN_PAYMENT_SUCCESS_PROCESS';

export const UPDATE_ADDRESS_BEFORE_SYNCING =
  'landers/Checkout/UPDATE_ADDRESS_BEFORE_SYNCING';

export const SYNC_CART_TO_MGT = 'landers/Checkout/SYNC_CART_TO_MGT';
export const SYNC_CART_MGT_FINISHED = 'landers/Checkout/SYNC_CART_MGT_FINISHED';

export const SAVE_PAY_PLACE_ORDER = 'landers/Checkout/SAVE_PAY_PLACE_ORDER';
export const IS_GUEST_CART_UPDATED = 'landers/Checkout/IS_GUEST_CART_UPDATED';

export const SAVE_PAY_PLACE_ORDER_SUCCESS =
  'landers/Checkout/SAVE_PAY_PLACE_ORDER_SUCCESS';

export const LOAD_DELIVERY_ADDRESS_SCREEN =
  'landers/Checkout/LOAD_DELIVERY_ADDRESS_SCREEN';

export const CALCULATE_BIZ_REBATE = 'landers/Checkout/CALCULATE_BIZ_REBATE';
export const CLEAR_BIZ_REBATE = 'landers/Checkout/CLEAR_BIZ_REBATE';

const CONSTANTS = {
  summary: {
    subtotal: '₱0.00',
    shopperFee: '₱0.00',
    deliveryFee: '₱0.00',
    isFreeDelivery: false,
  },
  shopperFeeDes:
    'Your Personal Shopper has been trained extensively to understand quality standards and to choose the best products for you! Expiry dates will be checked and your items will be handpicked in the right order to keep the cold chain. Proper packaging and handing over to our logistics partners will also be ensured for you.',
  termsAndServicesParams: {
    uri: '/terms-and-conditions',
    previousScreen: 'CheckoutCart',
  },
  totals: {
    allSolaneItem: 'all_solane_item',
    allGTCAldisItem: 'all_gtc_aldis_item',
    solaneDeliveryFee: 'solane_delivery_fee',
    gtcAldisDeliveryFee: 'gtc_aldis_delivery_fee',
    solaneBaseDeliveryFee: 'solane_base_delivery_fee',
    gtcAldisBaseDeliveryFee: 'gtc_aldis_base_delivery_fee',
  },
};

export default CONSTANTS;

export const UPDATE_TEMP_ITEMS = 'landers/Checkout/UPDATE_TEMP_ITEMS';

export const APPLY_REBATE = 'landers/Checkout/APPLY_REBATE';
export const LOAD_REBATE_ERROR_MESSAGE =
  'landers/Checkout/REBATE_ERROR_MESSAGE';

export const CREATE_GUEST_CART = 'landers/Checkout/CREATE_GUEST_CART';
